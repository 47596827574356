<template>
	<phi-page>
		<div slot="toolbar" class="toolbar">
			<mu-icon-button @click="$router.go(-1)" icon="arrow_back" />
			<h1>{{ $t('noun.map') }}</h1>
		</div>

        <phi-geo-feed :person="app.user"></phi-geo-feed>
	</phi-page>
</template>

<script>
import app from "../../store/app.js";
import PhiGeoFeed from "../../components/Phi/Geo/Feed.vue";

export default {
    name: "GeoFeed",
    components: {PhiGeoFeed},

    data() {
        return {
            app
        }
    }
};
</script>

<style lang="scss" scoped>
.phi-geo-feed {
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>
