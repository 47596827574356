<template>
	<div class="phi-geo-feed">
        <div class="target-list">

            <div v-if="showFilters" class="target-list-filters">
                <div class="filter-toggle" @click="filtersIsOpen = !filtersIsOpen">
                    <mu-icon :value="filtersIsOpen ? 'remove' : 'add'" :size="15"></mu-icon>
                    <div>{{ $t('phi.geo.feed.filters') }}</div>
                </div>

                <phi-drawer :open="filtersIsOpen">
                    <div class="filter-container">
                        <div class="target-filter">
                            {{ $t('phi.geo.feed.sortBy') }}
                            <select v-model="filter.sort">
                                <option value="name">{{ $t('phi.geo.feed.name') }}</option>
                                <option value="timestamp">{{ $t('phi.geo.feed.activity') }}</option>
                            </select>
                        </div>

                        <div class="target-filter">
                            <mu-checkbox
                                native-value="1"
                                v-model="filter.hideInactive"
                                :label="filter.hideInactive ? $t('phi.geo.feed.inactiveHidden') : $t('phi.geo.feed.inactiveShown')"
                                uncheck-icon="visibility"
                                checked-icon="visibility_off"
                            ></mu-checkbox>
                        </div>
                    </div>
                </phi-drawer>

            </div>

            <ul>
                <li
                    v-for="target in filteredTargets"
                    :key="target.id"
                    @click="focusTarget(target)"
                    :class="{disabled: !target.position}"
                >

                    <div class="header phi-media">
                        <div class="phi-media-figure">
                            <img v-if="target.icon" :src="target.icon" alt="">
                        </div>
                        <div class="phi-media-body">
                            <span v-if="target.name">{{ target.name }}</span>
                            <span v-else>Sin nombre ({{ target.id }})</span>

                            <div class="status" v-if="target.position">{{ $date(target.position.timestamp, "time") }}</div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <gmap-map
            :center="center"
            :zoom="zoom"
            style="width: 100%; height: 100%; min-height: 400px"
            ref="map"
        >
            <phi-target
                v-for="(target, i) in targets"
                :key="target.id"
                v-if="target.position"
                v-model="targets[i]"
            ></phi-target>
        </gmap-map>

	</div>
</template>

<script>
import * as VueGoogleMaps from "vue2-google-maps";
import Vue from "vue";
import app from "../../../store/app.js";
import PhiTarget from "../Target.vue";

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyCpSKazmH7T7ArBkdcPiRYaYG_QKhj7rRM",
        libraries: "geometry",
        v: "3.32"
    }
});


export default {
    name: "phi-geo-feed",
    components: {PhiTarget},

    i18n: {
        "es": {
            "phi.geo.feed.filters": "Filtrar",
            "phi.geo.feed.sortBy": "Ordenar por",
            "phi.geo.feed.name": "Nombre",
            "phi.geo.feed.activity": "Actividad",
            "phi.geo.feed.inactiveHidden": "Inactivos ocultos",
            "phi.geo.feed.inactiveShown": "Inactivos visibles",
        }
    },

    props: {
        person: {
            type: Object,
            required: true
        },

        // For admin use:   show ALL routes (in both subscriptions and feed)
        showAll: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            app,
            map: null,
            zoom: 16,
            center: {
                lat: typeof app.data.organization != "undefined" ? parseFloat(app.data.organization.latitude) : 0,
                lng: typeof app.data.organization != "undefined" ? parseFloat(app.data.organization.longitude) : 0
            },

            targets: [],
            isEnabled: false,
            refreshInterval: null,

            initialFocus: null,

            filter: {
                sort: "name",
                hideInactive: false
            },

            filtersIsOpen: false
        }
    },

    mounted() {
        /* For vue2-google-maps => 0.10.2*/
        VueGoogleMaps.loaded.then(() => {
            this.$refs.map.$mapPromise.then(map => {
                this.map = map;

                map.setOptions({
                    rotation: true,
                    autoRefresh: true
                });

                this.initialize();
            });
        });
    },

    beforeDestroy() {
        this.stop();
    },

    computed: {
        showFilters() {
            return this.targets && this.targets.length >= 5;
        },

        filteredTargets() {
            return this.targets
                .filter(target => {
                    return !this.filter.hideInactive || !!target.position;
                })
                .sort((a,b) => {
                    if (this.filter.sort == "name") {
                        return (a.name < b.name) ? -1 : (a.name > b.name ? 1 : 0);
                    } else {
                        // sort by activity DESCENDING
                        let stampA = a.position ? a.position.timestamp : 0;
                        let stampB = b.position ? b.position.timestamp : 0;
                        return (stampA < stampB) ? 1 : (stampA > stampB ? -1 : 0);
                    }
                });
        }
    },

    methods: {
        initialize() {
            this.loadSubscriptions()
                .then(this.start);
        },

        start() {
            if (this.isEnabled) {
                return;
            }

            this.isEnabled = true;
            this.refreshPositions();
            this.refreshInterval = setInterval(this.refreshPositions, 3000);
        },

        stop() {
            clearInterval(this.refreshInterval);
            this.isEnabled = false;
        },

        loadSubscriptions() {
            return app.api.get(`v3/transport/routes`)
                .then(routes => {
                    let V3routes = [];

                    routes.forEach(route => V3routes.push(parseInt(route.id)));

                    app.api.get(`people/${this.person.id}/geo/subscriptions`, {_all: this.showAll ? 1 : null})
                        .then(subscriptions => {
                            subscriptions.forEach(subscription => {
                                let target = subscription.target;
                                target.position = null;

                                if (V3routes.includes(parseInt(target.identifier))) {
                                    this.targets.push(target);
                                }

                            });
                        });
                });
        },

        refreshPositions() {
            let queryString = {
                _noCache: new Date().getTime(),
                _all: this.showAll ? 1 : undefined,
                targets: !this.showAll && this.targets.length ? this.targets.map(tgt => tgt.id).join() : undefined
            };

            let seenTargets = [];

            return app.api
                .get(`people/${this.person.id}/geo/feed`, queryString)
                .then(feed => {
                    feed.forEach(record => {
                        if ( seenTargets.indexOf(record.target.id) >= 0 ) {
                            return;
                        }

                        for (let i = 0; i < this.targets.length; i++) {
                            if (this.targets[i].id == record.target.id) {
                                this.targets[i].position = record;
                                break;
                            }
                        }
                        seenTargets.push(record.target.id);

                        if (!this.initialFocus) {
                            this.map.setCenter(new google.maps.LatLng(record.latitude, record.longitude));
                            this.initialFocus = true;
                        }
                    })
                });
        },

        focusTarget(target) {
            if (!target.position) {
                alert("No sabemos donde esta!");
                return;
            }

            let coords = new google.maps.LatLng(target.position.latitude, target.position.longitude);
            this.map.panTo(coords);
        }
    }
}
</script>

<style lang="scss" scoped>
.phi-geo-feed {
    position: relative;
}

.target-list {
    position: absolute;
    z-index: 2;

    top: 70px;
    left: 8px;
    bottom: 8px;

    overflow-y: auto;

    .target-list-filters {

        background-color: #fff;

        .filter-toggle {
            font-size: 12px;
            cursor: pointer;
            padding: 8px;

            display: flex;
            align-items: center;

            & > div {
                flex: 1;
                margin-left: 6px;
            }
        }

        .filter-container {
            padding: 8px;
            padding-bottom: 16px;
        }

        .target-filter {
            font-size: 13px;
            font-weight: bold;
            padding: 8px;
            opacity: .8;
        }
    }


    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            background-color: #fff;
            padding:6px 16px;
            margin-bottom: 5px;

            border-radius: 2px;
            box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 4px -1px;

            cursor: pointer;

            .header {
                padding: 0;
                align-items: center;

                .phi-media-figure {
                    margin-right: 8px;
                }

                img {
                    max-width: 24px;
                    max-height: 24px;
                }
            }

            .status {
                font-size: 11px;
                opacity: .7;
                margin: 0;
                padding: 0;
            }


            &.disabled {
                pointer-events: none;
                opacity: .85;
                background-color: #f3f3f3;
            }
        }
    }
}
</style>
